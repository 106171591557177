<template>
  <v-card>
    <v-card-title>
      {{ isEdit ? "Editar" : "Crear Nueva" }} Entrada
      {{ isEdit ? newTickets.name : "" }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form class="mt-6" ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="6">
            <v-select
              style="border-radius: 10px !important"
              :items="allSections"
              filled
              :item-value="[`.key`]"
              rounded
              dense
              :disabled="isEdit"
              :readonly="isEdit"
              @change="quantityTicketsSecti"
              :rules="requerid"
              v-model="newTickets.section"
              required
              item-text="name"
              label="Sección para esta entrada"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              style="border-radius: 10px !important"
              :items="filterAllSchedule"
              filled
              :item-value="[`.key`]"
              rounded
              @change="quantityTicketsSecti"
              dense
              :rules="requerid"
              v-model="newTickets.ticketDate"
              required
              label="Fecha y hora (cuándo es la función)"
            >
              <template v-slot:selection="{ item }">
                {{ item.date }} - {{ item.time | filtertime }}
              </template>
              <template v-slot:item="{ item }">
                <v-chip outlined class="mr-1"> {{ item.date }}</v-chip>
                -
                <v-chip outlined class="ml-1">
                  {{ item.time | filtertime }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              style="border-radius: 10px"
              label="Nombre de la entrada"
              filled
              :disabled="isEdit"
              :readonly="isEdit"
              v-model="newTickets.name"
              :rules="requerid"
              required
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <!-- <v-text-field
              style="border-radius: 10px"
              label="Cantidad de estas entradas disponibles"
              filled
              v-model="newTickets.availableAmount"
            
              required
              @keypress="isNumber"
              type="number"
              rounded
              dense
            ></v-text-field> -->

            <label>Cantidad de estas entradas disponibles</label>
            <v-slider
              v-model.number="newTickets.stock"
              
              :max="quantity"
              :rules="newTickets.amountSold ? requeridAmaunt : requerid"
              persistent-hint
              thumb-label="always"
              :disabled="quantity == 0"
              ticks
            >
              <template v-slot:prepend>
                <v-text-field
                  style="border-radius: 10px"
                  filled
                  v-model="newTickets.stock"
                  @keypress="isNumber"
                  rounded
                  required
                  :rules="ruleCapacity"
                  dense
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  style="border-radius: 10px"
                  disabled
                  filled
                  v-model="quantity"
                  @keypress="isNumber"
                  rounded
                  dense
                ></v-text-field>
              </template>
            </v-slider>
          </v-col>

          <v-col cols="2">
            <v-switch
              dense
              label="Es gratis"
              :disabled="isEdit"
              :readonly="isEdit"
              v-model="newTickets.isFree"
            ></v-switch>
          </v-col>
          <v-col cols="4">
            <v-text-field
              style="border-radius: 10px"
              label="Precio de esta entrada"
              filled
              v-model.number="newTickets.price"
              :rules="newTickets.price == 0 ? [] : requerid"
              required
              :disabled="isEdit"
              :readonly="isEdit"
              @keypress="isNumber"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              style="border-radius: 10px"
              label="Límite de venta máximo por usuario"
              filled
              v-model.number="newTickets.maxLimitPerUser"
              :rules="limitUserMax"
              required
              @keypress="isNumber"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newTickets.salesDateStart"
                  label="Fecha inicial de venta de Boletos"
                  persistent-hint
                  append-icon="fa-calendar-alt"
                  readonly
                  filled
                  :rules="requerid"
                  rounded
                  style="border-radius: 10px"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newTickets.salesDateStart"
                no-title
                :min="hoy"
                :max="selectedEvent.dateEnd"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <!-- <v-text-field
              label="Hora inicial de venta"
              style="border-radius: 10px"
              :rules="requerid"
              filled
              type="time"
              hide-details
              append-icon="fa-solid fa-clock"
              required
              v-model="newTickets.saleTimeStart"
              rounded
            >
            </v-text-field> -->
            <v-select
              rounded
              :rules="requerid"
              label="Hora inicial de venta"
              style="border-radius: 10px"
              append-icon="fa-solid fa-clock"
              filled
              :items="allTime"
              v-model="newTickets.saleTimeStart"
            />
          </v-col>

          <v-col cols="3">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newTickets.salesDateEnd"
                  label="Fecha final de venta"
                  persistent-hint
                  append-icon="fa-calendar-alt"
                  readonly
                  filled
                  :rules="requerid"
                  rounded
                  style="border-radius: 10px"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newTickets.salesDateEnd"
                no-title
                :min="hoy"
                :max="selectedEvent.dateEnd"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <!-- <v-text-field
              label="Hora final de venta"
              style="border-radius: 10px"
              :rules="requerid"
              filled
              type="time"
              hide-details
              append-icon="fa-solid fa-clock"
              required
              v-model="newTickets.saleTimeEnd"
              rounded
            >
            </v-text-field> -->
            <v-select
              rounded
              :rules="requerid"
              label="Hora final de venta"
              style="border-radius: 10px"
              append-icon="fa-solid fa-clock"
              filled
              :items="allTime"
              v-model="newTickets.saleTimeEnd"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2" v-if="false">
          <v-col cols="6">
            <v-text-field
              style="border-radius: 10px"
              label="#de Entrada (en boleto impreso)"
              filled
              v-model="newTickets.ticketCode"
              :disabled="isEdit"
              :readonly="isEdit"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              style="border-radius: 10px"
              label="#Mesa"
              filled
              v-model="newTickets.ticketTable"
              :disabled="isEdit"
              :readonly="isEdit"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              style="border-radius: 10px"
              label="#Silla"
              filled
              v-model="newTickets.ticketChair"
              type="text"
              :disabled="isEdit"
              :readonly="isEdit"
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="3">
            <v-switch
              dense
              label="Activo"
              v-model="newTickets.active"
            ></v-switch>
          </v-col>
          <v-col cols="3" v-if="user.type == 'sudo'">
            <v-switch
              dense
              label="Activo por ocho"
              v-model="newTickets.ochoActive"
            ></v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch
              dense
              label="Muestra contador"
              v-model="newTickets.showsCounter"
            ></v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch
              dense
              label="Requiere atención"
              v-model="newTickets.requiresAttention"
            ></v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch
              dense
              label="Está agotado"
              v-model="newTickets.isSoldOut"
            ></v-switch>
          </v-col>
             <v-col cols="3">
            <v-switch
              dense
              label="Es preventa"
              v-model="newTickets.isPresale"
            ></v-switch>
          </v-col>
          <v-col cols="3">
            <v-switch
              dense
              label="Tiene multiplicador"
              v-model="newTickets.hasMultiplier"
            ></v-switch>
          </v-col>
 
          <v-col cols="3" v-if="newTickets.hasMultiplier">
            <v-text-field
              style="border-radius: 10px"
              label="Multiplicador"
              filled
              v-model.number="newTickets.multiplier"
              :rules="requeridMultipler"
              required
              @keypress="isNumber"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>
    
       
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="actions-btn">
      <v-btn
        color="primary"
        text
        :loading="btnloading"
        style="border-radius: 10px"
        @click="$emit('closed')"
        >cancelar</v-btn
      >
      <v-btn
        :loading="btnloading"
        color="primary"
        width="200px"
        v-if="isEdit"
        class="ml-12"
        style="border-radius: 10px"
        @click="updateTickets"
        :disabled="!valid"
      >
        Editar</v-btn
      >
      <v-btn
        :loading="btnloading"
        color="primary"
        width="200px"
        v-if="!isEdit"
        class="ml-12"
        style="border-radius: 10px"
        @click="addUpdateTickets"
        :disabled="!valid"
      >
        Agregar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import store from "@/store/index.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

var randomstring = require("randomstring");

import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import formNewTickets from "@/components/formNewTickets.vue";
export default {
  name: "formNewTickets",
  props: ["dataEdit"],
  components: {},
  data() {
    return {
      quantity: 0,
      ruleCapacity: [
        (v) => !!v || "Campo requerido",
        (v) =>
          (v && v <= this.quantity) ||
          "Capacidad debe ser menor a la capacidad máxima",
      ],
      menu2: false,
      allTickets: [],
      menu: false,
      allSections: [],
      allSchedules: [],
      isEdit: false,
      cities_: [],
      allTime: [
        { text: "07:00 AM", value: "07:00" },
        { text: "07:30 AM", value: "07:30" },
        { text: "08:00 AM", value: "08:00" },
        { text: "08:30 AM", value: "08:30" },
        { text: "09:00 AM", value: "09:00" },
        { text: "09:30 AM", value: "09:30" },
        { text: "10:00 AM", value: "10:00" },
        { text: "10:30 AM", value: "10:30" },
        { text: "11:00 AM", value: "11:00" },
        { text: "11:30 AM", value: "11:30" },
        { text: "12:00 PM", value: "12:00" },
        { text: "12:30 PM", value: "12:30" },
        { text: "01:00 PM", value: "13:00" },
        { text: "01:30 PM", value: "13:30" },
        { text: "02:00 PM", value: "14:00" },
        { text: "02:30 PM", value: "14:30" },
        { text: "03:00 PM", value: "15:00" },
        { text: "03:30 PM", value: "15:30" },
        { text: "04:00 PM", value: "16:00" },
        { text: "04:30 PM", value: "16:30" },
        { text: "05:00 PM", value: "17:00" },
        { text: "05:30 PM", value: "17:30" },
        { text: "06:00 PM", value: "18:00" },
        { text: "06:30 PM", value: "18:30" },
        { text: "07:00 PM", value: "19:00" },
        { text: "07:30 PM", value: "19:30" },
        { text: "08:00 PM", value: "20:00" },
        { text: "08:30 PM", value: "20:30" },
        { text: "09:00 PM", value: "21:00" },
        { text: "09:30 PM", value: "21:30" },
        { text: "10:00 PM", value: "22:00" },
        { text: "10:30 PM", value: "22:30" },
        { text: "11:00 PM", value: "23:00" },
        { text: "11:30 PM", value: "23:30" },
        { text: "12:00 AM", value: "00:00" },
        { text: "12:30 AM", value: "00:30" },
        { text: "01:00 AM", value: "01:00" },
        { text: "01:30 AM", value: "01:30" },
        { text: "02:00 AM", value: "02:00" },
        { text: "02:30 AM", value: "02:30" },
        { text: "03:00 AM", value: "03:00" },
        { text: "03:30 AM", value: "03:30" },
        { text: "04:00 AM", value: "04:00" },
        { text: "04:30 AM", value: "04:30" },
        { text: "05:00 AM", value: "05:00" },
        { text: "05:30 AM", value: "05:30" },
        { text: "06:00 AM", value: "06:00" },
        { text: "06:30 AM", value: "06:30" },
      ],
      tab: null,
      valid: true,
      Code: null,
      imagedefaul: require("@/assets/templateImage.svg"),
      dialog: false,
      loading: true,
      btnloading: false,
      hoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      File: null,
      menu2: false,
      requerid: [(v) => !!v || "Campo requerido"],
      limitUserMax: [(v) => !!v || "Campo requerido",
      (v) => v <= this.newTickets.stock && v > 0 || "La cantidad debe ser menor a entradas disponibles y mayor a cero",
      ],
      requeridMultipler: [(v) => !!v || "Campo requerido",
      (v) => v <= this.newTickets.multiplier && v > 0 || "El valor debe ser mayor a cero",
      ],
      requeridAmaunt: [
        (v) => !!v || "Campo requerido",
        (v) =>
          v >= this.newTickets.amountSold ||
          "Debe de ser mayor a la cantidad vendida",
      ],

      newTickets: {},
    };
  },
  computed: {
    ...mapState(["business", "user", "selectedEvent"]),
    filterAllSchedule() {
      return this.allSchedules.filter(
        (i) =>
          i.date >= this.selectedEvent.dateStart &&
          i.date <= this.selectedEvent.dateEnd
      );
    },
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY");
    },
    filtertime(time) {
      let timeForma = {
        "07:00": "07:00 AM",
        "07:30": "07:30 AM",
        "08:00": "08:00 AM",
        "08:30": "08:30 AM",
        "09:00": "09:00 AM",
        "09:30": "09:30 AM",
        "10:00": "10:00 AM",
        "10:30": "10:30 AM",
        "11:00": "11:00 AM",
        "11:30": "11:30 AM",
        "12:00": "12:00 PM",
        "12:30": "12:30 PM",
        "13:00": "01:00 PM",
        "13:30": "01:30 PM",
        "14:00": "02:00 PM",
        "14:30": "02:30 PM",
        "15:00": "03:00 PM",
        "15:30": "03:30 PM",
        "16:00": "04:00 PM",
        "16:30": "04:30 PM",
        "17:00": "05:00 PM",
        "17:30": "05:30 PM",
        "18:00": "06:00 PM",
        "18:30": "06:30 PM",
        "19:00": "07:00 PM",
        "19:30": "07:30 PM",
        "20:00": "08:00 PM",
        "20:30": "08:30 PM",
        "21:00": "09:00 PM",
        "21:30": "09:30 PM",
        "22:00": "10:00 PM",
        "22:30": "10:30 PM",
        "23:00": "11:00 PM",
        "23:30": "11:30 PM",
        "00:00": "12:00 AM",
        "00:30": "12:30 AM",
        "01:00": "01:00 AM",
        "01:30": "01:30 AM",
        "02:00": "02:00 AM",
        "02:30": "02:30 AM",
        "03:00": "03:00 AM",
        "03:30": "03:30 AM",
        "04:00": "04:00 AM",
        "04:30": "04:30 AM",
        "05:00": "05:00 AM",
        "05:30": "05:30 AM",
        "06:00": "06:00 AM",
        "06:30": "06:30 AM",
      };
      return timeForma[time];
    },
  },
  methods: {
    ...mapActions([
      "_searchTerm",
      "Alert_",
      "addEventSelection",
      "visibleSearch",
    ]),
    async quantityTicketsSecti(value) {
      this.quantity = 0;

      if (
        this.newTickets.ticketDate &&
        this.newTickets.section &&
        this.allSections &&
        this.allTickets
      ) {
        var sections = this.allSections.find(
          (s) => s[`.key`] == this.newTickets.section
        );

        var tickets = this.allTickets.filter(
          (x) =>
            x.section == this.newTickets.section &&
            x.ticketDate == this.newTickets.ticketDate
        );

        var quantityMax = sections.maxQuantity;
       // console.debug("quantityMax", quantityMax);
        for (let i = 0; i < tickets.length; i++) {
          const tick = tickets[i];

          quantityMax = quantityMax - tick.stock;
        }

        if (this.isEdit) {
          var quan = this.newTickets.stock;
          return (this.quantity = quan + quantityMax);
        } else {
          return (this.quantity = quantityMax);
        }
      }
    },
    generateCode() {
      if (this.newTickets.name) {
        return `${this.newTickets.name
          .toUpperCase()
          .substr(0, 4)}-${randomstring.generate(6).toUpperCase()}`;
      } else {
        return `ENT-${randomstring.generate(6).toUpperCase()}`;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async addUpdateTickets() {
      this.btnloading = true;

      // let token = await this.getSectionToken;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const tickets = this.newTickets;
        tickets.dateStart = this.selectedEvent.dateStart;
        tickets.dateEnd = this.selectedEvent.dateEnd;
        tickets.country = this.selectedEvent.country;
        tickets.city = this.selectedEvent.city;
        tickets.event = this.selectedEvent[`.key`];
        tickets.deleted = false;
        tickets.maxLimitPerUser = Number(this.newTickets.maxLimitPerUser);
        tickets.amountBlocked = 0;
        tickets.amountSold = 0;
        tickets.availableAmount = Number(this.newTickets.stock);
        tickets.stock =  Number(this.newTickets.stock);
        tickets.business = this.business[`.key`];
        tickets.createdAt = new Date();
        tickets.createdBy = this.user[`.key`];
        tickets.code = this.generateCode();
        tickets.isSoldOut = this.newTickets.isSoldOut || false;
        tickets.hasMultiplier = this.newTickets.hasMultiplier || false;
        tickets.multiplier = this.newTickets.multiplier || 0;
        tickets.isPresale = this.newTickets.isPresale || false;
        tickets.price = this.newTickets.isFree ? 0 : this.newTickets.price;
        tickets.isFree = this.newTickets.price == 0 ? true : false;
        tickets.isFree = this.newTickets.isFree || false;

        //// console.debug(tickets);
        db.collection("oViveTickets")
          .add(tickets)
          .then(async (ref) => {
            this.Alert_({
              text: "Entrada creada correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("closed");
              this.$emit("success");
            }, 2000);
          })
          .catch((error) => {
           console.error(error);
            this.Alert_({
              text: "Ocurrió un error al crear la entrada",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnloading = false;
            this.$emit("closed");
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnloading = false;
      }
    },
    async updateTickets() {
      this.btnloading = true;
      // let token = await this.getSectionToken;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const tickets = this.newTickets;
        tickets.modifiedAt = new Date();
        tickets.modifiedBy = this.user[`.key`];
       // console.debug(tickets);
        db.collection("oViveTickets")
          .doc(this.dataEdit[`.key`])
          .update(tickets)
          .then(async (ref) => {
            this.Alert_({
              text: "Entrada Editada correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("closed");
              this.$emit("success");
            }, 2000);
          })
          .catch((error) => {
           console.error(error);
            this.Alert_({
              text: "Ocurrió un error al editar la entrada",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnloading = false;
            this.$emit("closed");
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnloading = false;
      }
    },
  },
  async mounted() {
    this._searchTerm("Eventos");
    this.visibleSearch(true);

    await this.$binding(
      "allSections",
      db
        .collection("events")
        .doc(this.selectedEvent[`.key`])
        .collection("sections")

        .where("business", "==", this.business[`.key`])
        .where("active", "==", true)
        .where("deleted", "==", false)
    );
    await this.$binding(
      "allSchedules",
      db
        .collection("events")
        .doc(this.selectedEvent[`.key`])
        .collection("dates")
        .where("active", "==", true)
        .where("deleted", "==", false)
        .orderBy("date", "asc")
    );

    await this.$binding(
      "allTickets",
      db
        .collection("oViveTickets")
        .where("event", "==", this.selectedEvent[`.key`])
        .where("deleted", "==", false)
    );

    if (this.dataEdit) {
      this.isEdit = true;
      this.newTickets = JSON.parse(JSON.stringify(this.dataEdit));
      await this.quantityTicketsSecti(this.newTickets.section);

      delete this.newTickets[`.key`];
    } else {
      this.isEdit = false;
    }
  },
};
</script>
<style lang="scss">
.theme--dark.v-tabs-items {
  background-color: #1e1e1e00 !important;
}
.upload {
  display: none;
}
.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
  color: #000 !important;
}
</style>
