<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <headerLayaut
      :subtitle="`${selectedEvent.name} ${selectedEvent.dateStart} - ${selectedEvent.dateEnd}`"
    >
      <v-btn
        color="primary"
        width="200px"
        style="border-radius: 10px"
        class="mr-2 mt-5"

        v-if="autorized.includes(user.type)"
        @click="dialog = true"
        >Crear nueva entrada</v-btn

      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            fab
            v-on="on"
            class="mr-1"
            @click="toExcel"
            dark
            :loading="loading"
          >
            <v-icon dark>fa-file-excel</v-icon>
          </v-btn>
        </template>
        <span>Exportar data a .CSV</span>
      </v-tooltip>
      <!-- <v-btn color="primary" style="border-radius: 10px" class="mr-2"
        >Cargar Excel</v-btn
      > -->
    </headerLayaut>
    <!-- contenido de la pagina -->

    <div class="body-template" v-if="!loading">
      <v-data-table
        :headers="getHeaders"
        :items="tickets"
        sort-by="createdAt"
        fixed-header
        :search="$store.state.search"
        :calculate-widths="true"
        :loading="loading"
        sort-desc
        item-key="id"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }"
      >
        <template v-slot:[`item.code`]="{ item }">
          <v-chip color="rgba(255, 255, 255, 0.165)" @click="copyToClipboard(item.code)">
            <span>
              {{ item.code }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.amountSold`]="{ item }">
          <v-chip color="#41b883">
            <span>
              {{ item.amountSold || 0 }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.amountUsed`]="{ item }">
          <v-chip :color="item.amountUsed >=  1 ? 'error':'grey'">
            <span>
              {{ item.amountUsed || 0 }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.availableAmount`]="{ item }">
          <v-chip color="grey">
            <span>
              {{ item.availableAmount }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.stock`]="{ item }">
          <v-chip color="info">
            <span>
              {{ item.stock }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span>
            {{ item.price | currency }}
          </span>
        </template>
        <template v-slot:[`item.sectionName`]="{ item }">
          <v-chip color="rgba(255, 255, 255, 0.165)">
            <span>
              {{ item.sectionName }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.ticketDateFilter`]="{ item }">
          <v-chip color="rgba(255, 255, 255, 0.165)">
            <span>
              {{ item.ticketDateFilter }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-switch
            dense
          :readonly="!autorized.includes(user.type)"
          :disabled="!autorized.includes(user.type)"
            v-model="item.active"
            @change="
              switchControlChanged(
                { active: item.active },
                item['.key'],
                item,
                'active'
              )
            "
          ></v-switch>
        </template>
        <template v-slot:[`item.isFree`]="{ item }">
          <span>{{ item.isFree ? "Si" : "No" }}</span>
          <!-- @change="switchControlChanged({ active: item.active }, item)" -->
        </template>
        <template v-slot:[`item.showsCounter`]="{ item }">
          <v-switch
            dense
            :readonly="!autorized.includes(user.type)"
          :disabled="!autorized.includes(user.type)"
            v-model="item.showsCounter"
            @change="
              switchControlChanged(
                { showsCounter: item.showsCounter },
                item['.key'],
                item,
                'showsCounter'
              )
            "
          ></v-switch>
          <!-- @change="switchControlChanged({ active: item.active }, item)" -->
        </template>
        <template v-slot:[`item.actions`]="{ item }"  v-if="autorized.includes(user.type)">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                @click="(selectEdit = item), (dialogEdit = true)"
                :elevation="0"
                color="info"
              >
                <v-icon small dark>fa-solid fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Editar entrada {{ item.name }}</span>
          </v-tooltip>
          <v-tooltip left    v-if="autorized.includes(user.type)">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                style="border-radius: 10px"
                small
                :elevation="0"
                @click="(selectEdit = item), (dialogDeleted = true)"
                color="#d42828"
              >
                <v-icon small dark>fa-solid fa-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Eliminar entrada {{ item.name }}</span>
          </v-tooltip>
        </template>
        <template slot="body.append">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                class="ma-4"
                v-on="on"
                style="cursor: pointer"
                color="primary"
                @click="modalShowColumns = true"
              >
                fas fa-eye-slash
              </v-icon>
            </template>
            <span>Mostrar / Ocultar columnas</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <v-dialog
      v-model="dialog"
      v-if="dialog"
      scrollable
      max-width="1200px"
      persistent
    >
      <formNewTickets @closed="closedDialogs" @success="ActionsSuccess" />
    </v-dialog>
    <v-dialog
      v-model="dialogEdit"
      v-if="dialogEdit"
      scrollable
      max-width="1200px"
      persistent
    >
      <formNewTickets
        v-if="dialogEdit"
        :dataEdit="selectEdit"
        @success="ActionsSuccess"
        @closed="closedDialogs"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogDeleted"
      v-if="dialogDeleted"
      min-width="350px"
      max-width="400px"
    >
      <v-card class="pa-2">
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          small
          absolute
          @click="closedDialogs"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
        <v-card-title class="pr-4"
          >¿Eliminar entrada {{ selectEdit.name }}?
        </v-card-title>

        <v-card-actions class="flex-end">
          <v-btn color="primary" :loading="btnLoading" @click="deletedTickets">
            Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SHOW AND HIDE TABLE COLUMNS  -->
    <v-dialog
      max-width="450"
      persistent
      v-if="modalShowColumns"
      v-model="modalShowColumns"
      scrollable
    >
      <show-columns
        @success="modalShowColumns = false"
        @cancel="modalShowColumns = false"
        :headers="headers"
      />
    </v-dialog>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import store from "@/store/index.js";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import showColumns from "@/components/show-hide-columns";
import headerLayaut from "@/components/header-layaut.vue";
import formNewTickets from "@/components/formNewTickets.vue";
import { Parser } from "json2csv";
// import * as moment from "moment";

import { db, fb } from "@/firebase";
export default {
  name: "Tickets",

  components: {
    headerLayaut,
    showColumns,
    formNewTickets,
  },
  data() {
    return {
      modalShowColumns: false,
      btnLoading: false,
      dialog: false,
      loading: false,
      dialogEdit: false,
      dialogDeleted: false,
      selectEdit: null,
      allSections: [],
      allSchedules: [],
      tickets: [],
      autorized:["sudo",'viveSudo',"viveLesserAdmin","viveOperations","viveBusiness"],
      headers: [
        { text: "Código", active: true, value: "code", align: "start" },
        {
          text: "Nombre",
          align: "start",
          active: true,
          value: "name",
        },
        { text: "Sección", active: true, value: "sectionName" },
        { text: "Precio", active: true, value: "price" },
        { text: "Fecha de entrada", active: true, value: "ticketDateFilter" },
        { text: "Inicio de venta", active: true, value: "salesDateStart" },
        { text: "Final de venta", active: true, value: "salesDateEnd" },
        { text: "Vendidos", active: true, value: "amountSold" },
        { text: "Redimidos", active: true, value: "amountUsed" },
        { text: "Disponibles", active: true, value: "availableAmount" },
        { text: "Stock Inicial", active: true, value: "stock" },
        { text: "#Entrada", active: true, value: "ticketCode", whith: "200px" },
        { text: "#Mesa", active: true, value: "ticketTable" },
        { text: "#Silla", active: true, value: "ticketChair" },

        { text: "Muestra contador", active: true, value: "showsCounter" },
        { text: "Es gratis", active: true, value: "isFree" },
        { text: "Activo", active: true, value: "active" },
        { text: "Acciones", active: true, value: "actions" },
      ],
    };
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
    filtertime(time) {
      let timeForma = {
        "07:00": "07:00 AM",
        "07:30": "07:30 AM",
        "08:00": "08:00 AM",
        "08:30": "08:30 AM",
        "09:00": "09:00 AM",
        "09:30": "09:30 AM",
        "10:00": "10:00 AM",
        "10:30": "10:30 AM",
        "11:00": "11:00 AM",
        "11:30": "11:30 AM",
        "12:00": "12:00 PM",
        "12:30": "12:30 PM",
        "13:00": "01:00 PM",
        "13:30": "01:30 PM",
        "14:00": "02:00 PM",
        "14:30": "02:30 PM",
        "15:00": "03:00 PM",
        "15:30": "03:30 PM",
        "16:00": "04:00 PM",
        "16:30": "04:30 PM",
        "17:00": "05:00 PM",
        "17:30": "05:30 PM",
        "18:00": "06:00 PM",
        "18:30": "06:30 PM",
        "19:00": "07:00 PM",
        "19:30": "07:30 PM",
        "20:00": "08:00 PM",
        "20:30": "08:30 PM",
        "21:00": "09:00 PM",
        "21:30": "09:30 PM",
        "22:00": "10:00 PM",
        "22:30": "10:30 PM",
        "23:00": "11:00 PM",
        "23:30": "11:30 PM",
        "00:00": "12:00 AM",
        "00:30": "12:30 AM",
        "01:00": "01:00 AM",
        "01:30": "01:30 AM",
        "02:00": "02:00 AM",
        "02:30": "02:30 AM",
        "03:00": "03:00 AM",
        "03:30": "03:30 AM",
        "04:00": "04:00 AM",
        "04:30": "04:30 AM",
        "05:00": "05:00 AM",
        "05:30": "05:30 AM",
        "06:00": "06:00 AM",
        "06:30": "06:30 AM",
      };
      return timeForma[time];
    },
  },
  computed: {
    ...mapState(["business", "user", "selectedEvent"]),
    getHeaders() {
      return this.headers.filter((item) => item.active == true);
    },
  },

  async mounted() {
    await this.getInfo();
  },
  methods: {
    ...mapActions(["_searchTerm", "Alert_", "visibleSearch"]),
    ActionsSuccess() {
      this.$nextTick(() => {
        this.getInfo();
      });
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.Alert_({
            text: `(${text}) Copiado al portapapeles`,
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "info",
          });
        })
        .catch((err) =>console.error("Async: Could not copy text: ", err));
    },
    toExcel() {
      const filename = "data.csv";
      // const fields = ["status", "step", "createdAt"];
      const fields = [
        {
          label: "Código",
          value: "code",
        },
        {
          label: "Nombre",
          value: "name",
        },
        {
          label: "Comercio",
          value: (row) => {
            try {
              return `${this.business.shortName}`;
            } catch (error) {
              return `No disponible, verificar con un administrador`;
            }
          },
        },
        {
          label: "Sección",
          value: "sectionName",
        },
        {
          label: "Precio",
          value: "price",
        },
        {
          label: "Fecha de entrada",
          value: "ticketDateFilter",
        },
        {
          label: "Inicio de venta",
          value: "salesDateStart",
        },
        {
          label: "Final de venta",
          value: "salesDateEnd",
        },
        {
          label: "Vendidos",
          value: "amountSold",
        },
        {
          label: "Redimidos",
          value: (row) => {
            try {
              return `${Number.parseFloat(
                row.amountSold ? row.availableAmount - row.amountSold : 0
              ).toFixed(2)}`;
            } catch (error) {
              return `No disponible`;
            }
          },
          // "productsSubTotal*businessCurrentPercentage"
        },
        {
          label: "Disponibles",
          value: "available",
        },
        {
          label: "#Entrada",
          value: "ticketCode",
        },

        {
          label: "#Mesa",
          value: "ticketTable",
        },
        {
          label: "Muestra contador",
          value: "showsCounter",
        },
        {
          label: "Es gratis",
          value: "isFree",
        },
        {
          label: "Activo",
          value: "active",
        },
      ];

      const json2csvParser = new Parser({
        fields,
        excelStrings: true,
        withBOM: true,
      });
      const csv = json2csvParser.parse(this.tickets);

      //console.log(csv);

      let blob = new Blob([csv], { type: "text/csv" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    deletedTickets() {
      this.btnLoading = false;
      if (this.selectEdit) {
        db.collection("oViveTickets")
          .doc(this.selectEdit[`.key`])
          .update({
            deletedAt: new Date(),
            deletedBy: this.user[`.key`],
            active: false,
            deleted: true,
          })
          .then(() => {
            this.Alert_({
              text: "Entrada eliminada correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "info",
            });
            this.btnLoading = false;
            this.closedDialogs();
          })
          .catch((error) => {
           // console.debug(error);
            this.Alert_({
              text: "Ocurrió un error al eliminar la entrada",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.closedDialogs();
            this.btnLoading = false;
          });
      }
    },
    switchControlChanged(data, eventId, business, field) {
      let prevData = Object.assign({}, data);
      prevData[field] = !data[field];

      if (data && eventId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.user[`.key`];
       // console.debug(data);
        db.collection("oViveTickets")
          .doc(eventId)
          .update(data)
          .then(() => {
            this.Alert_({
              text: "Información de la entrada actualizada correctamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "#00cdbc",
            });
          })
          .catch((err) => {
           console.log(err);
            this.Alert_({
              text: "Ocurrió un error inesperado, inténtelo nuevamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });

            business[field] = !business[field];
          });
      }
    },
    closedDialogs() {
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogDeleted = false;
      this.selectEdit = null;
    },
    async getInfo() {
      this.loading = true;
      this._searchTerm("entradas");
      this.visibleSearch(true);
      await this.$binding(
        "allSections",
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("sections")
          .where("business", "==", this.business[`.key`])
      );
      await this.$binding(
        "allSchedules",
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("dates")
          .where("business", "==", this.business[`.key`])
      );
      await this.$binding(
        "tickets",
        db.collection("oViveTickets")
          .where("event", "==", this.selectedEvent[`.key`])
          .where("business", "==", this.business[`.key`])
          .where("deleted", "==", false)
      );

      await this.tickets.forEach((element) => {
        var date = this.allSchedules.find(
          (e) => e[`.key`] == element.ticketDate
        );
        element.ticketDateFilter = `${
          date.date
        } - ${this.$options.filters.filtertime(date.time)}`;
        let section = this.allSections.find(
          (i) => i[`.key`] == element.section
        );
        element.sectionName = section.name;
      });
      this.loading = false;
    },
  },
  watch: {
    tickets(e) {
      e.forEach((element) => {
        var date = this.allSchedules.find(
          (e) => e[`.key`] == element.ticketDate
        );

        if (date && date.date) {
          element.ticketDateFilter = `${
            date.date
          } - ${this.$options.filters.filtertime(date.time)}`;
        }
        let section = this.allSections.find(
          (i) => i[`.key`] == element.section
        );
        if (section && section.name) {
          element.sectionName = section.name;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-application.text-start {
  text-align: start !important;
  white-space: nowrap !important;
}
</style>
